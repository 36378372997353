import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import SyntaxHighLighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { codeStyle } from './style';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import a11yDark from "react-syntax-highlighter/dist/esm/styles/prism/a11y-dark";
import python from "react-syntax-highlighter/dist/esm/languages/prism/python";


const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const PyMomo = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'JavaScript & TypeScript version',
    paragraph: 'The library implement directly .d.ts files, so you can easily use it for TypeScript'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  
                  </div>
                <h3 className="mt-0 mb-12">
                  Installation
                  </h3>
                <p className="m-0">
                  Install it directly from Pypi package manager <br /> Requierement : Python3+, pip3
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <SyntaxHighlighter language="javascript" style={a11yDark} >
                    {
                        "npm install mobilemoney.js"
                    }
                </SyntaxHighlighter>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  
                  </div>
                <h3 className="mt-0 mb-12">
                  Code Example
                  </h3>
                <p className="m-0">
                  This is an example based on sandbox. The library is set to production by default, make sure to use .isSandbox() method to turn in sandbox env.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                
                <SyntaxHighLighter language="typescript" style={docco} customStyle={{background:"dark", color:"white", }} >
                    {
                        "const {Client} = require('mobilemoney.js')\n(async()=>{\n  let user = new Client();\n  user.isSandbox();\n  let subscriptionKey = ''\n  let uuid = user.getReferenceId();\n  let [done,] = await user.createApiUser(uuid, subscriptionKey);\n})()"
                    }
                </SyntaxHighLighter>
              </div>
            </div>

            <div className="split-item reveal-from-center">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  
                  </div>
                <h3 className="mt-0 mb-12">
                  Documentation
                  </h3>
                  <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Read our Documentation 
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="white" wideMobile href="https://momojs.rewriteapi.cm">
                    Documentation
                    </Button>
                  <Button tag="a" color="dark" wideMobile href="https://github.com/rewriteapi/mobilemoney.js">
                    View on Github
                    </Button>
                </ButtonGroup>
              </div>
              
            </div>
            
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/docjavascript.svg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
              
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

PyMomo.propTypes = propTypes;
PyMomo.defaultProps = defaultProps;

export default PyMomo;
