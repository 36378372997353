import React from 'react';
// import sections
import Hero from '../components/sections/HeroMomo';
import FeaturesTiles from '../components/sections/FeaturesTilesMomo';
import PyMomo from '../components/sections/PyMomo';
import JsMomo from '../components/sections/JsMomo';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Momo = () => {

  return (
    <>
      <Hero className="illustration-section-momo" />
      <FeaturesTiles />
      <PyMomo invertMobile topDivider imageFill className="illustration-section-momo-py" id='py' />
      <JsMomo invertMobile topDivider imageFill className="illustration-section-momo-js" id='js' />
      <Testimonial topDivider />
      <Cta split />
    </>
  );
}

export default Momo;